import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "course-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#course-project",
        "aria-label": "course project permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Course Project`}</h2>
    <p>{`The course project is an open-ended, hands-on opportunity to explore an aspect of statistical NLP. Examples include ...`}</p>
    <ul>
      <li parentName="ul">{`Summarize your participation in a Kaggle competition or shared task with an upcoming deadline`}</li>
      <li parentName="ul">{`Compare the capabilities of several open source generative decoders-only transformers (ex. GPT-style models) on a particular task`}</li>
      <li parentName="ul">{`Design and create a synthetic dataset, and demonstrate of how it can help to address a particular gap in capabilities`}</li>
      <li parentName="ul">{`Fine-tune a multimodal LLM for a new task (or language) and report its performance`}</li>
    </ul>
    <h2 {...{
      "id": "rubric",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#rubric",
        "aria-label": "rubric permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rubric`}</h2>
    <p>{`Your course project proposal will be assessed on a `}<strong parentName="p">{`Superior/Pass/Fail`}</strong>{` basis.`}</p>
    <h3 {...{
      "id": "superior-3-pts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#superior-3-pts",
        "aria-label": "superior 3 pts permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Superior (3 pts)`}</h3>
    <p><strong parentName="p">{`Pass`}</strong>{` criteria + all of the following:`}</p>
    <ul>
      <li parentName="ul">{`project is `}<strong parentName="li">{`appropriately scoped`}</strong>{` to the course (i.e., not overly ambitious)`}</li>
      <li parentName="ul">{`rough timeline (with dates)`}</li>
    </ul>
    <h3 {...{
      "id": "pass-2-pts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pass-2-pts",
        "aria-label": "pass 2 pts permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pass (2 pts)`}</h3>
    <p>{`All of the following:`}</p>
    <ul>
      <li parentName="ul">{`pull request submitted to course blog repository by the deadline`}</li>
      <li parentName="ul">{`clear outline of the goals of your final project`}</li>
      <li parentName="ul">{`project proposal investigates some aspect of statistical NLP`}</li>
      <li parentName="ul">{`post includes a link to a course project code repository`}</li>
      <li parentName="ul">{`the code repository uses the required assignment template (i.e., the repository is owned by the appropriate GitHub organization)`}</li>
    </ul>
    <p>{`NOTE: Missing one or more of the above will result in a 1 point deduction.`}</p>
    <h3 {...{
      "id": "fail-0-pts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fail-0-pts",
        "aria-label": "fail 0 pts permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fail (0 pts)`}</h3>
    <p>{`Submission is empty or altogether absent`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      